
.tabsMain{
    padding-top: 5px;
    padding-left: 10px !important;
}
.tabsMain .nav-link {
    box-shadow: inset 1px 1px 1px 1px gray !important;
    cursor: pointer !important;
    background-color: var(--primaryLight) !important;
}
.tabsMain .active {
    color: white !important;
    background-color: var(--primaryDark) !important;
}
.tabContent{
    width: 100%;
    height: auto;
    overflow: auto;
    background-color: lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 5vw;
}