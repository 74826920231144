.tasksContainer {
    position: sticky;
    right: 0;
    top: 20px;
    width: auto;
    z-index: 1;
}

.tasksSidebar {
    position: fixed;
    right: 0;
    top: 15px;
    height: 95vh;
    width: 280px;
    padding: 7px;
    border-radius: 3px;
    background-color: lightgray;
    border: 4px solid var(--primaryLight);
    box-shadow: 0px 0px 0px 2px lightgrey;
}
#taskListContainer{
    position: static;
    margin-top: 8%;
    margin-right: auto;
    margin-left: auto;
    height: 80% !important;
}
#dashboardSidebarContentContainer{
    height: 90%;
    margin-top: 5%;
    overflow: auto;
}

.taskHeader{
    border-bottom: 4px solid white !important;
    padding-bottom: 5px !important;
    margin-right: 10px !important;
}

.description{
    color: white !important;
}
.taskDiv {
    margin-left: 10px;
}

#openTasksButton{
    transform: rotate(270deg);
    position: absolute;
    right: -46px;
    top: 42px;
    width: 120px;
    color: white;
    background-color: var(--primaryDark);
    border: 1px solid white;
    border-radius: 0px;
    transition: 0.5s ease;
}
#openTasksButton:hover{
    color: var(--primaryDark);
    background-color: lightgray;
    border: 2px solid var(--primaryDark);
    width: 124px;
    transition: 0.5s ease;
}
#closeTasksButton{
    color: white;
    background-color: var(--primaryDark);
    transition: 0.5s ease;
}
#closeTasksButton:hover{
    color: var(--primaryDark);
    background-color: white;
    transition: 0.5s ease;
}
.dashboardLinkButton{
    background-color: var(--primaryLight) !important;
    transition: 0.5s ease;
    margin-left: 27px !important;
}
.dashboardLinkButton:hover{
    color: white !important;
    background-color: var(--primaryDark) !important;
    transition: 0.5s ease;
}