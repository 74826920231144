
.govAccordion{
    position: static;
    width: 96% !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}
.dropdownIcon{
    width: 30px !important;
    transition: 0.5s ease;
    transform: rotate(90deg);
    margin-right: 30px;

}
.removeGov{
    margin-top: 20px!important;
}
.removeGov:hover{
    color: red !important;
}
.stateIcon{
    max-height: 40px !important;
    margin-left: 10px;
    position: static !important;
    margin-right: 10px;
}
.userGovAccordion{
    display: flex !important;
    justify-content: space-between !important;
    transition: 0.5s ease;
}
.userGovAccordion.active{
    transition: 0.5s ease;
}
.userGovAccordion h3{
    color: white !important;
}
.userGovAccordion.active h3{
    color: black !important;
}
.userGovAccordion.active .dropdownIcon{
    transform: rotate(0deg);
    transition: 0.5s ease;
}
.userGovAccordion.city{
    background-color: var(--cityColorLight) !important;
}
.userGovAccordion.consolidated{
    background-color: var(--cityColorLight) !important;
}
.userGovAccordion.county{
    background-color: var(--countyColorLight) !important;
}
.userGovAccordion.state{
    background-color: var(--stateColorLight) !important;
}
.userGovAccordion.federal{
    background-color: var(--federalColorLight) !important;
}
.governmentSelectContainer{
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--containerGray);
    border-radius: 3px;
    box-shadow: 3px 3px 2px gray;
}
.govAccordionContainer{
    min-height: 20vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: var(--containerGray);
    border-radius: 3px;
    box-shadow: 3px 3px 2px gray;
    margin-top: 10px;
}
.governmentSelectContainer h3 {
    width: 50%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-bottom: 5px;
    border-bottom: 1px solid gray !important;
    margin-bottom: 5px !important;
}

.govAddButton {
    cursor: pointer !important;
}

.ui.header:first-child {
    margin-top: 0.857143em;
}

/* ************************************************************ */
/* **********  Begin Styling Gov Individual Content *********** */
/* ************************************************************ */


.govContentContainer{
    border-radius: 3px;
    min-height: 20vh;
    margin: 3%;
    background-color: var(--containerGray);
    transition: 0.5s ease;
}

.govContentContainer.active{
    transition: 0.5s ease;
}
.addContentIcon {
    color: blue;
    margin-left: 10px !important;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s ease;
}
.addContentIcon:hover {
    color: lightgreen;
    transition: 0.5s ease;
}

.govContentContainerSidebar{
    background-color: var(--bodyBackground) !important;
}

/* **************************************** */
/* ****** Content container styling ******* */


.contentCardsContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.userContentCard{
    width: 25% !important;
    margin: 1vw !important;

}

.cardImage{
    /* border: 1px solid black; */
    box-shadow: 1px 1px 0px 0px gray;
    border-radius: 50px;
    background-color: white !important;
}

.cardDescription.description{
    color: black !important;
}

.govOfficialCard{
    background-color: lightblue !important;
}
.departmentCard{
    background-color: lightgreen!important;
}
.committeeCard{
    background-color: lightpink!important;
}
.legislationCard{
    background-color: lightseagreen!important;
}
.eventCard{
    background-color: lightsalmon!important;
}
.electionCard{
    background-color: lightslategray!important;
}
.serviceCard{
    background-color: lightyellow !important;
}

/* ******************************************* */
/* ************Edit Content******************* */

.editContentModalContainer{
    height: 60% !important;
    top: 15% !important;
    left: 25% !important;
    background-color: var(--primaryDark) !important;

}

.editContentModal.content{
    display: flex !important;
    flex-direction: column !important;
    background-color: var(--primaryDark) !important;
}
.editContentModal .input{
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.editContentModal .selection{
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.editContentModal .button{
    width: 30% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15px !important;
}
.editContentModal .button:hover{
    color: white;
    background-color: lightgreen !important;
}
.editFormHeader {
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 8px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 2px;
    background-color: var(--primaryLight) !important;
    color: white !important;
}