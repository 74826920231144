
.mapToolbar{
    position: static !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2vh;
    top: 5px;
    box-shadow: 0px 0px 5px 5px lightgrey;

}
.mapContainer .navbar {
    position: static !important;
}

.levelSelect{
    border: none !important;
    margin-right: 2px;
    color: #6c757d !important;
    background-color: #f8f9fa !important;
}
.levelSelect:hover{
    color: #f8f9fa !important;
    cursor: pointer !important;
}
.levelSelect.city:hover{
    background-color: var(--cityColor) !important;
}
.levelSelect.county:hover{
    background-color: var(--countyColor) !important;
}
.levelSelect.state:hover{
    background-color: var(--stateColor) !important;
}
.levelSelect.federal:hover{
    background-color: var(--federalColor) !important;
}
.dropDownContainer {
    max-height: 200px !important;
    overflow: auto;
}

.selectState.ui.selection.dropdown{
    min-width: 11em !important;
}

.searchStateIcon {
    margin-top: 3px !important;
    margin-left: 5px !important;
    font-size: 20px !important;
    cursor: pointer !important;
}