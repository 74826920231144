

header {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 10px;
    width: 33%;
    border-radius: 4px;
    box-shadow: 2px 2px 2px gray;
    text-align: center;
    background-color: var(--primaryDark);
    color: white;

}

.stateMapContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
}

.stateOutline {
    padding: 30px;
    border: 5px solid black;
}

g path {
    fill: var(--primaryDark);
}
g path:hover {
    fill: var(--bondiBlue)
}

.cityDots {
    fill: black;
}
.cityDots:hover {
    fill: var(--pastelGreen);
    cursor: pointer;
}


text tspan{
    background-color: white !important;
    color: white;
    padding: 1px;
    width: 1% !important;
    font-size: 2px;
    z-index: 2;
}

.mapToggleButton {
    width: 100% !important;
    border-radius: 0px !important;
    border: none !important;
}
.openToggle {
    color: darkgray !important;
    background-color: var(--primaryLight) !important;
}
.closedToggle {
    color: white !important;
    background-color: var(--primaryDark) !important;
}