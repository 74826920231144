
:root {

    --bodyBackground: #f9f9f9;
    /* --bodyBackground: #2c2c2d; */
    --nav: #b3b3b3;
    --primaryDark: rgb(105, 53, 184);
    --primaryLight: #ac7be0;
    --accent1: rgb(229, 232, 233);
    --accent2: rgb(82, 82, 82);

    /* ******* Gov Level Colors ********** */

    --cityColor: #713b88;
    --cityColorLight: #ac7be0;
    --countyColor: #c77700; 
    --countyColorLight: #ddc964;
    /* --countyColorLight: #dccc7b; */
    --stateColor: #73b72f;
    --stateColorLight: #9fc777;
    --federalColor: #4caaad;
    --federalColorLight: #8ccacc;

    /* Coolors Exported Palette - coolors.co/35cfff-74ea56-f6ff00-ffb600-ff4800 */
    --backgroundColorMain: #ac7be0;
    --containerGray: #e9ecef;
    --vividSkyBlue: rgba(53, 207, 255, 1);
    --niceBlue: rgba(57, 182, 255, 1);
    /* --royalishBlue: rgba(8, 97, 218, 1); */
    --royalishBlue: rgb(105, 53, 184);
    --navBarColor: rgb(93, 69, 147);
    --pastelGreen: rgba(116, 234, 86, 1);
    --lemonGlacier: rgba(246, 255, 0, 1);
    --orange: rgba(255, 182, 0, 1);
    --red: rgba(255, 72, 0, 1);

/* Coolors Exported Palette - coolors.co/eaebed-006989-a3bac3-007090-01a7c2 */

    --isabelline: rgba(234, 235, 237, 1);
    --independence: rgba(63, 79, 109, 1);
    --wildBlueYonder: rgba(163, 186, 195, 1);
    --seaBlue: rgba(0, 112, 144, 1);
    --bondiBlue: rgba(1, 167, 194, 1);
}


/* ************************************************ */
/* *** Begin Styling for Universal Components ***** */
/* ************************************************ */


/* ***** Find NavBar in NavBar.css */
/* ***** Find Footer in Footer.css*/

body{
    background-color: var(--bodyBackground) !important;
}

.stateBanner{
    color: var(--isabelline);
    background-color: var(--independence);
    width: 40%;
    border-radius: 5px;
    box-shadow: 2px 2px 2px gray;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    text-align: center;
    padding: 10px;
}

#mainContainer{
    position: relative;
    height: 87vh;
    width: 100vw;
}

#bodyWrapper {
    /* position: relative; */
    min-height: 78vh;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px !important;
    
}

.card{
    position: static !important;
}

@media(max-width:1300px){
    .mapContainer{
        max-width: 850px !important;
    }

}


/* @media (min-width: 1300px) {
    .mapContainer{
        margin-top: 0px !important;
    }

    #openTasksButton{
        top: 40vh !important;
        width: 500px !important;
        right: -238px !important;
    }

    #bodyWrapper {
        position: relative;
        min-height: 93vh !important;
        margin-top: 2vh !important;
        margin-left: 375px;
        width: 65vw !important;
        height: 100%;
    }

    .navContainer{
        flex-direction: column !important;
        position: fixed !important;
        top: 0;
        left: 0 !important;
        height: 100% !important;

    }
    .navDropDownContainer{
        align-items: flex-start !important;
    }

    #navLogin {
        margin-bottom: 200% !important;
    }

    .navLinksContainer {
        flex-direction: column !important;
        margin-top: 4vh;
    }
    .navLink{
        text-align: left !important;
        width: 200px !important;
    }

    .footerLinks {
        margin-left: 40% !important;
    }
    .footerContainer {
        margin-top: 0px !important;
    }
    

} */

@media (max-width: 384px){

    .navContainer{
        justify-content: center !important;
    }

}