@import "../Participate.css";

.footerContainer {
    position: static !important;
    bottom: 0 !important;
    width: 100%;
    margin-top: 45px;
}

.footer {
    background-color: var(--primaryLight);
    padding-bottom: 20px;
}
#mainFooter .alert{
    margin-bottom: 0 !important;
}
.footerLinks {
    margin-left: 8%;
}