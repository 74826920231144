

.fullMapContainer {
    padding: 3% 6%;
    border: 1px solid #eee;    
    border-radius: 10px;
    box-shadow: 13px 13px 20px #cbced1,
    -13px -13px 20px #f0f0f0;
    margin: 15px 10px;  
}

.mapContainer {
  
    max-width: 1000px;
    padding-top: 10px;
    margin-right: auto;
    margin-left: auto; 
    text-align: center;      
}
.mapHeader{
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 1px lightgray;
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--primaryDark) !important;
    box-shadow: 5px 2px 5px 3px lightgrey;
    border-radius: 1px;
    margin-bottom: 0 !important;
}
.jumbo {
    box-shadow: 1px 1px 2px 2px lightgray;
    background-color: #e9ecef;
    border-radius: 5px;
    padding: 15px !important;
}
.actualMap {
    margin-top: 5px;
    margin-bottom: 5px;
}

/* fill doesn't work when each path is wrapped in a link */

#AK,#AL,#AZ,#AR,#CA,#CO,#CT,#DE,#FL,#GA,#HI,#ID,#IL,#IN,#IA,#KS,#KY,#LA,#ME,#MD,#MA,#MI,#MN,#MS,#MO,#MT,#NE,#NV,#NH,#NJ,#NM,#NY,#NC,#ND,#OH,#OK,#OR,#PA,#RI,#SC,#SD,#TN,#TX,#UT,#VT,#VA,#WA,#WV,#WI,#WY,#AS,#GU,#MP,#PR {
    fill: var(--primaryDark)
}

.disabledState:hover{
    cursor: pointer;
    fill: var(--red) !important;
}
.activeState:hover {
    cursor: pointer;
    fill: var(--bondiBlue) !important;
}

svg {
    cursor: pointer;
}

.mapCollapseContainer {
    margin-bottom: 30px;
}

/* #AK:hover,#AL:hover,#AZ:hover,#AR:hover,#CA:hover,#CO:hover,#CT:hover,#DE:hover,#FL:hover,#GA:hover,#HI:hover,#ID:hover,#IL:hover,#IN:hover,#IA:hover,#KS:hover,#KY:hover,#LA:hover,#ME:hover,#MD:hover,#MA:hover,#MI:hover,#MN:hover,#MS:hover,#MO:hover,#MT:hover,#NE:hover,#NV:hover,#NH:hover,#NJ:hover,#NM:hover,#NY:hover,#NC:hover,#ND:hover,#OH:hover,#OK:hover,#OR:hover,#PA:hover,#RI:hover,#SC:hover,#SD:hover,#TN:hover,#TX:hover,#UT:hover,#VT:hover,#VA:hover,#WA:hover,#WV:hover,#WI:hover,#WY:hover,#AS:hover,#GU:hover, #MP:hover,#PR:hover, 
#VI:hover {
    cursor: pointer;
    fill: var(--bondiBlue) !important; 
} */

