@import "../Participate.css";

.welcomeMessage .modal-content{
    width: 100% !important;
    height: 90% !important;
}
@media(max-width:450px){
    .welcomeMessage .modal-content{
        height: 125% !important;
    }
}
.welcomeMessage button{
    background-color: var(--primaryDark);
    border-color: var(--primaryDark);
    transition: 0.25s ease;
}
.welcomeHeader{
    color: white;
    background-color: var(--primaryDark);
}
.welcomeMessage:first-child{
    margin-top: 10% !important;
    height: 60% !important;
}

.pageContainer {
    border-top: 1px solid black;
}
