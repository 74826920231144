@import "../Participate.css";


.navContainer {
    background-color: var(--primaryLight);
}
.navContainer.navbar{
    padding: 0 !important;
}

#logo {
    height: 100%;
    width: 150px;
    margin-left: 30px;
}

.navDropDownContainer {
    border-radius: 3px;
    padding-left: 12px;
}
.navLinksContainer{
    margin-left: 5%;
    width: 60% !important;
    display: flex !important;
    justify-content: space-between !important;
}
.navLink{
    color: rgb(52, 52, 52) !important;
    transition: 0.5s ease;
    border-radius: 2px;

}
.navLink:hover{
    color: var(--accent1) !important;
    background-color: var(--primaryDark) !important;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.5s ease;
}
.navButton{
    background-color: var(--primaryDark) !important;
    border-color: var(--primaryDark) !important;
}
.loginPopUp{
    margin-top: 18vh !important;
}
.logoutModal{
    margin-top: 35vh !important;
    width: 265px !important;
}
.modal-dialog{
    z-index: 9999 !important;
    filter: none !important;
}
.modal-open #root{
    z-index: 5 !important;
    filter: blur(2.5px);
}

.loginPopUp .modal-content{
    width: 100% !important;
}

.loginPopUp .modal-content .nav-tabs{
    color: white;
    background-color: var(--primaryDark) !important;
    border-color: var(--primaryLight) !important;
}
.loginPopUp .modal-content .nav-tabs .active{
    color: rgb(50, 50, 50);
    background-color: var(--primaryLight) !important;
    border-color: var(--primaryLight) !important;
}
.loginPopUp .modal-footer .btn{
    background-color: var(--primaryDark) !important;
    border-color: var(--primaryLight) !important;
}
.loginPopUp .modal-content .modal-header{
    color: rgb(50, 50, 50) !important;
    background-color: var(--primaryLight) !important;
    border-color: var(--primaryLight);
    border-radius: 0 !important;
}
.navContainer .ui.toggle.checkbox label:after{
    z-index: 1 !important;
}
.navContainer .ui.toggle.checkbox label:before {
    background: lightgray !important;
}
.navContainer button{
    margin: 25px;
    margin-right: 7vw !important;
    text-decoration: none;
}
.navContainer{
    list-style: none !important;
}
.loadingNavbar{
    display: flex !important;
    left: 93vw !important;
    
}